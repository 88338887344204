import type { RouteRecordRaw } from 'vue-router'
import {
  CategoryDateViewRoute,
  CategoryItemViewRoute,
  CategoryMainViewRoute
} from '@/2_pages/Category'
import { CompilationViewRoute } from '@/2_pages/Compilation'
import { SettingsViewRoute, ConfigurationErrorRoute } from '@/2_pages/Configuration'
import { EventViewRoute } from '@/2_pages/Event'
import { HomeRoute } from '@/2_pages/Home'
import { SeanceViewRoute } from '@/2_pages/Seance'
import { SearchCategoryViewRoute, SearchViewRoute } from '@/2_pages/Search'
import {
  NotFondRoute,
  NotFoundRedirectRoutes,
  InternalServerErrorRoute,
  UiKitRoute,
  ConfigurationThemeConstructorRoute
} from '@/2_pages/Utils'
import { VenueViewRoute } from '@/2_pages/Venue'

export const routes: RouteRecordRaw[] = [
  HomeRoute,
  // utils
  NotFondRoute,
  NotFoundRedirectRoutes,
  InternalServerErrorRoute,
  UiKitRoute,
  ConfigurationThemeConstructorRoute,
  // search
  SearchViewRoute,
  SearchCategoryViewRoute,
  //event
  EventViewRoute,
  SeanceViewRoute,
  //venue
  VenueViewRoute,
  //configuration
  SettingsViewRoute,
  ConfigurationErrorRoute,
  //category
  CategoryMainViewRoute,
  CategoryItemViewRoute,
  CategoryDateViewRoute,
  //compilation
  CompilationViewRoute
]
