import { defineStore } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { computed, ref } from 'vue'
import { AbortError } from '@/6_shared/api'
import { usePageLoading } from '@/6_shared/composables'
import type { Pagination } from '@/6_shared/model'
import { venueService } from '../api'
import { type Venue, VenueAdapter, type VenueDTO, type VenueFilters } from '../model'

export const useVenue = defineStore('venue', () => {
  const venue = ref<Undefinable<Venue>>(undefined)
  const setVenue = (venueData: VenueDTO) => (venue.value = new VenueAdapter(venueData))

  const { initialized, load } = usePageLoading()
  const getVenue = async (id: number) => {
    load(async () => {
      const venue = await venueService.get(id)
      setVenue(venue)
    })
  }

  //repertoire
  const pagination = computed((): Undefinable<Pagination> => venue.value?.repertoirePagination)
  const allRepertoireLoaded = computed(
    () => !!pagination.value && pagination.value.currentPage >= pagination.value.pagesCount
  )

  const {
    initialized: repertoireLoaded,
    load: loadRepertoire,
    error: repertoireLoadError
  } = usePageLoading(true)
  const getRepertoire = async (filters: VenueFilters) => {
    loadRepertoire(
      async () => {
        if (!venue.value) return
        const repertoire = await venueService.loadRepertoire(venue.value.id, filters)
        venue.value.updateRepertoire(repertoire)
        venue.value.updatePagination(repertoire?.pagination)
      },
      (error: any) => {
        if (error instanceof AbortError) return

        throw error
      }
    )
  }

  const {
    initialized: repertoireNextPageLoaded,
    load: loadRepertoirePage,
    error: repertoireLoadPageError
  } = usePageLoading(true)

  const loadRepertoireNextPage = (query: VenueFilters) => {
    loadRepertoirePage(
      async () => {
        if (!venue.value || !pagination.value) return

        const repertoire = await venueService.loadRepertoire(venue.value.id, {
          ...query,
          pagination: {
            ...query.pagination,
            currentPage: pagination.value.currentPage + 1
          }
        })

        venue.value.addRepertoireNextPage(repertoire)
      },
      (error: any) => {
        throw error
      }
    )
  }

  return {
    initialized,
    venue,
    getVenue,
    //repertoire
    pagination,
    loadRepertoireNextPage,
    allRepertoireLoaded,
    getRepertoire,
    repertoireLoaded,
    repertoireNextPageLoaded,
    repertoireLoadError,
    repertoireLoadPageError
  }
})
