import type { NavigationGuardWithThis, NavigationHookAfter } from 'vue-router'
import { yandexMetrika } from '@/5_entities/Analytics'
import { appMessenger } from '@/5_entities/Configuration'
import { cancelRequestService } from '@/6_shared/api'

const abortRequests = () => {
  cancelRequestService.abortRequestsByParam('needCancelOnRouteChange')
}

export const beforeEach: NavigationGuardWithThis<undefined> = ({ hash }, _from, next) => {
  const ignoreCancel = hash === '#ignoreCancel'
  !ignoreCancel && abortRequests()

  next()
}

export const afterEach: NavigationHookAfter = (to) => {
  yandexMetrika.trackPageChange()
  to.name && appMessenger.navigate(to.name as string)
}
