import { BaseService, type ErrorsHandbook, HttpService } from '@/6_shared/api'
import { type VenueDTO, type VenueFilters } from '../model'
import { venueErrorsHandbook } from './error'

class VenueService extends BaseService {
  private readonly _endpoint: string = 'venues'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public get(id: number) {
    return this._http.get<VenueDTO>([this._endpoint, id].join('/'), {
      generalUrl: 'venues:id',
      needCancelOnRouteChange: true,
      appMessageErrors: [404]
    })
  }

  public loadRepertoire(venueId: number, query?: VenueFilters) {
    return this._http.get<VenueDTO['repertoire']>([this._endpoint, venueId, 'events'].join('/'), {
      generalUrl: 'venues:id:repertoire',
      needCancelOnRouteChange: true,
      queryAsString: true,
      query
    })
  }
}

const venueService = new VenueService(HttpService, venueErrorsHandbook)

export { venueService }
