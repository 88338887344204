import type { RouteRecordRaw } from 'vue-router'

export const CategoryItemViewRoute: RouteRecordRaw = {
  path: '/category/:id',
  name: 'CategoryItemView',
  component: () => import('../ui/CategoryItemView.vue'),
  meta: {
    layout: 'empty'
  }
}
